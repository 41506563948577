import React, { Component } from "react";
import rgr from "../logos/raidguild-red.png";
import rgtr from "../logos/raidguild-tr.png";
import chart from "../logos/chart.png";
import btc from "../logos/btc.png";
import eth from "../logos/eth.png";
import link from "../logos/link.png";
import uni from "../logos/uni.png";
import dot from "../logos/dot.png";
import dai from "../logos/dai.png";
import whale from "../logos/whale.png";
// import aragon from "../logos/aragon.png";
import balancer from "../logos/balancer.png";
// import decentraland from "../logos/decentraland.png";
import filecoin from "../logos/filecoin.png";
// import gnosis from "../logos/gnosis.png";
import kusama from "../logos/kusama.png";
import livepeer from "../logos/livepeer.png";
// import unibright from "../logos/unibright.png";
import xdai from "../logos/xdai.png";
import yearn from "../logos/yearn.png";
import aave from "../logos/aave.png";
import inch from "../logos/inch.png";
import sand from "../logos/sand.png";
import bancor from "../logos/bancor.png";
// import robot from "../logos/robot.png";
import bat from "../logos/bat.png";
import injective from "../logos/injective.png";
import sushi from "../logos/sushi.png";
import dogecoin from "../logos/dogecoin.png";
import chi from "../logos/chi.png";
import grt from "../logos/grt.png";
import matic from "../logos/matic.png";



require("dotenv").config();

const axios = require("axios");

class App extends Component {
  async componentWillMount() {
    await this.getData();
  }

  //getting&setting cryptocurrencies data
  getData = () => {
    //getting ccurencies data
    axios({
      method: "GET",
      url: "https://coinpaprika1.p.rapidapi.com/tickers",
      headers: {
        "content-type": "application/octet-stream",
        "x-rapidapi-host": "coinpaprika1.p.rapidapi.com",
        //        "x-rapidapi-key":"RAPID_API_KEY",
        "x-rapidapi-key": "b0ddfad9cemsh47a0f1cbd1dedc7p122a48jsnbd76945b412d",

        useQueryString: true,
      },
    })
      .then((response) => {
        //assign all ccurencies data from API into variable
        const coins = response.data;
        //declare ccurencies and their imgs
        const ccArray = [
          { name: "Aave", img: aave },
          // { name: "Aragon", img: aragon },
          { name: "Balancer", img: balancer },
          { name: "Bancor", img: bancor },
          { name: "Bitcoin", img: btc },
          { name: "Chainlink", img: link },
          { name: "Dai", img: dai },
          // { name: "Decentraland", img: decentraland },
          { name: "Ethereum", img: eth },
          { name: "Filecoin", img: filecoin },
          // { name: "Gnosis", img: gnosis },
          { name: "Kusama", img: kusama },
          { name: "Livepeer", img: livepeer },
          { name: "Polkadot", img: dot },
          { name: "The Sandbox", img: sand },
          // { name: "Unibright", img: unibright },
          { name: "Uniswap", img: uni },
          { name: "1INCH", img: inch },
          { name: "WHALE", img: whale },
          { name: "xDAI Stake", img: xdai },
          // { name: "Robot", img: robot },
          { name: "Basic Attention Token", img: bat },
          { name: "yearn.finance", img: yearn },
          { name: "Sushi", img: sushi },
          { name: "Injective Protocol", img: injective },
          { name: "Dogecoin", img: dogecoin },
          { name: "Chi Gastoken", img: chi },
          { name: "The Graph", img: grt },
          { name: "Matic Network", img: matic },
          
        ];

        /* search for chosen cryptocurrencies, then add them to the state */
        //get ccurency from ccArray
        for (let j = 0; j < ccArray.length; j++) {
          //get ccurrency from API
          for (let i = 0; i < coins.length; i++) {
            //if current ccurrency API == current ccurrency from ccArray
            if (coins[i].name === ccArray[j].name) {
              //add img to the ccurrency API data
              coins[i]["img"] = ccArray[j].img;
              //set state with updated data
              this.setState({
                ccData: [...this.state.ccData, coins[i]],
              });
            }
          }
        }

        //sort ccurrencies by rank
        this.setState({
          ccData: this.state.ccData.sort((a, b) => a.rank - b.rank),
        });

        console.log(this.state.ccData);
      })

      .catch((error) => {
        console.log(error);
      });

    // console.log (response)
    // console.log (process.env)

    // export default RapidApiWrapper({
    //   apiKey: process.env.RAPID_API_KEY
    //   })(MapContainer);

    //getting ccurrency market data
    axios({
      method: "GET",
      url: "https://coinpaprika1.p.rapidapi.com/global",
      headers: {
        "content-type": "application/octet-stream",
        "x-rapidapi-host": "coinpaprika1.p.rapidapi.com",
        //        "x-rapidapi-key":"RAPID_API_KEY",
        "x-rapidapi-key": "b0ddfad9cemsh47a0f1cbd1dedc7p122a48jsnbd76945b412d",
        useQueryString: true,
      },
    })
      .then((response) => {
        const globalData = response.data;
        this.setState({ loading: true });
        // set state with updated global ccurency market cap
        // this.setState({ ccGlobalMcap: globalData.market_cap_usd });
        this.setState({ ccGlobalMcap: globalData.market_cap_change_24h });
        // this.setState({ ccGlobalMcap: globalData.volume_24h_usd });
        // this.setState({ ccGlobalMcap: globalData.percent_change_1y });

        this.setState({ loading: false });

        console.log(response);
      })

      .catch((error) => {
        console.log(error);
      });
  };

  constructor(props) {
    super(props);
    this.state = {
      ccData: [],
      ccGlobalMcap: "",
      loading: true,
    };
  }

  render() {
    return (
      <div>
        <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow text-monospace text-white">
          <a
            className="navbar-brand col-sm-3 col-md-2 mr-0 "
            href="http://web3skills.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={rgr}
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt=""
            />
            {" "}
            <img
              src={rgtr}
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt=""
            />
            {" "}
            <img
              src={chart}
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt=""
            />
            {" "}
            {}
            <h2>LexAutomatica Token Tracker</h2>
          </a>
          {this.state.loading ? (
            <div
              id="loader"
              className="nav-item text-nowrap d-none d-sm-none d-sm-block"
            >
              Loading...
            </div>
          ) : (
            <li className="nav-item text-nowrap d-none d-sm-none d-sm-block">
              <medium>24-hr Total Market Cap Change :</medium>&nbsp;
              <a
                className="text-white"
                href="https://coinpaprika.com/market-overview/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.state.ccGlobalMcap.toLocaleString("en-US")}
                {"%"}
              </a>
              &nbsp;
            </li>
          )}
        </nav>
        &nbsp;
        <div className="container-fluid mt-5 w-20 p-3">
          <div className="row">
            <main role="main" className="col-lg-12 d-flex text-right">
              <table className="table table-striped table-hover table-fixed table-bordered text-monospace">
                <caption>
                  Data Source:
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://coinpaprika.com/"
                  >
                    coinpaprika
                  </a>
                </caption>
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Rank</th>
                    <th scope="col">Logo</th>
                    <th scope="col">Name</th>
                    <th scope="col">Price</th>
                    <th scope="col">ATH</th>
                    <th scope="col">% of High</th>
                    {/* <th scope="col">Market Cap</th> */}
                    <th scope="col">24hr</th>
                    <th scope="col">7day</th>
                    <th scope="col">30day</th>
                    <th scope="col">1yr</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.ccData.map((data, key) => {
                    return (
                      <tr key={key}>
                        <td>{data.rank}</td>
                        <td>
                          <img
                            src={data.img}
                            width="25"
                            height="25"
                            className="d-inline-block align-top"
                            alt=""
                          />
                        </td>
                        <td>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={"https://coinpaprika.com/coin/" + data.id}
                          >
                            {data.name}
                          </a>
                        </td>
                        <td>${data.quotes.USD.price.toFixed(2)}</td>
                        <td>${data.quotes.USD.ath_price.toFixed(2)}</td>
                        <td>
                          {data.quotes.USD.percent_from_price_ath.toLocaleString(
                            "en-US"
                          )}
                          {"%"}
                        </td>
                        {/* <td>
                          ${data.quotes.USD.market_cap.toLocaleString("en-US")}
                        </td> */}
                        <td>
                          {data.quotes.USD.percent_change_24h.toLocaleString(
                            "en-US"
                          )}
                          {"%"}
                        </td>
                        <td>
                          {data.quotes.USD.percent_change_7d.toLocaleString(
                            "en-US"
                          )}
                          {"%"}
                        </td>
                        <td>
                          {data.quotes.USD.percent_change_30d.toLocaleString(
                            "en-US"
                          )}
                          {"%"}
                        </td>
                        <td>
                          {data.quotes.USD.percent_change_1y.toLocaleString(
                            "en-US"
                          )}
                          {"%"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </main>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
